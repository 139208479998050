<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="plus" @click="$router.push('bad-words/new')">Add Banned Words</sc-button>
            <sc-search :table="table"></sc-search>
        </div>
        <sc-table :table="table">
            <template #rows>
                <div v-for="row in table.rows()" class="row-container">
                    <div class="row">
                        <div class="name" @click="$router.push(`bad-words/${row.id}/edit`)">
                            {{ row.attributes.word }}
                        </div>
                        <div class="icon" @click="deleting = row">
                            <awesome-icon icon="trash-alt"></awesome-icon>
                        </div>
                    </div>

                </div>
            </template>
        </sc-table>
        <sc-modal v-if="deleting" @close="stopDeleting()">
            Do you wish to delete the word "{{ deleting.attributes.word }}"?
            <div class="btns">
                <sc-button @click="destroy(deleting)">Yes, delete</sc-button>
            </div>
        </sc-modal>
    </div>
</template>

<script>
import ScSearch from "@/components/sc-search";

export default {
  name: "all",
  components: {ScSearch},
  data() {
    return {
      table: this.$stable.createTable({
        name: 'All Banned Words',
        url: 'bad-words',
        sortBy: 'word',
        columns: {
          word: {
            target: 'word'
          }
        }
      }),
      deleting: null,
    }
  },

  computed: {},

  mounted() {
    this.table.fetch();
  },

  methods: {
    search() {
      this.table.fetch();
    },

    destroy(row) {
      this.$talker.api.delete(`bad-words/${row.id}`)
        .then(res => {
          this.$notify.success(`Bad word ${row.attributes.word} deleted`)
          this.stopDeleting();
        })
    },

    stopDeleting() {
      this.deleting = null;
      this.table.fetch();
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .table-container-with-inspect {
    .rows {
        display: grid;
        padding: 10px 0;
        grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );

        .row-container {
            padding: 0 25px;
            box-sizing: border-box;
        }

        .row {
            border-bottom: 1px solid #ddd;
            padding: 0 10px;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .name {
                flex: 1;
                padding: 5px 0;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
}

.btns {
    text-align: center;
    padding: 2em 0 0.5em;
}
</style>